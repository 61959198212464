import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectHomeGuardGuard  {
  private window: Window;
  constructor(private route: Router, @Inject(DOCUMENT) public document: Document) {
    this.window = document.defaultView;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isGermminar = environment.nome === 'Germminar';
    const urlDoesNotIncludeLP = !this.window.location.href.includes('lp');
    const startsWithBlog = this.window.location.href.split("//")[1].startsWith("blog");

    if(environment.production && state.url === '/'){
      if(isGermminar){
        if(startsWithBlog){
          return true;
        }
        this.route.navigateByUrl('/inicio');
        return false;
      }
      else if (urlDoesNotIncludeLP) {
        this.route.navigateByUrl('/login');
        return false;
      }
    }
    return true;
  }
}
