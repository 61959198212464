import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardHeaderLandingComponent } from './standard-header-landing.component';

@NgModule({
  declarations: [StandardHeaderLandingComponent],
  imports: [CommonModule, MatIconModule, FlexLayoutModule],
  exports: [StandardHeaderLandingComponent]
})
export class StandardHeaderLandingModule {}
