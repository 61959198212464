import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, from, fromEvent, Observable, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/domain/config.service';
import { environment } from 'src/environments/environment';
import { AccessControl } from '../models/acess-control';
import { ClicksControl } from '../models/clicks-control';
import { LandingAccessControl } from './../models/landing-access-control';
import { LandingButtonClicks } from './../models/landing-button-clicks';
import { LandingSignUpTries } from './../models/landing-signUp-tries';
import { UtilService } from './domain/util.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsEbarnService {
  private url: string;
  private unsub$: Subject<void>;
  private readonly appName = `cxm-frontend-${environment.nome.toLowerCase()}`;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private http: HttpClient,
    private router: Router,
    private deviceService: DeviceDetectorService,
    configService: ConfigService,
    private utilService: UtilService,
    private _storageService: StorageService
  ) {
    this.url = configService.getUrlServiceNode() + '/api/analytics-ebarn';
  }

  public startListenEvents(elementListenClicks: ElementRef<HTMLElement>): void {
    this.unsub$ = new Subject<never>();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        this.filter(),
        map<NavigationEnd, AccessControl>(event => ({
          page: event.url,
          ...this.getDataClient()
        })),
        switchMap(data =>
          from(this.utilService.getPosition()).pipe(
            map(latlng => {
              return { ...data, location: latlng };
            })
          )
        ),
        switchMap(body => this.saveAccessControl(body)),
        catchError((err, caught) => caught),
        takeUntil(this.unsub$)
      )
      .subscribe();

    fromEvent(elementListenClicks.nativeElement, 'click')
      .pipe(
        this.filter(),
        // map<PointerEvent, ClicksControl>(this.getDataEvent.bind(this)),
        // switchMap(body => this.saveClicksControl(body)),
        catchError((err, caught) => caught),
        takeUntil(this.unsub$)
      )
      .subscribe();
  }

  public stopListenEvents(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  private getDataEvent(event: PointerEvent): ClicksControl {
    return {
      page: this.router.url,
      tagName: (event.target as HTMLElement).tagName,
      className:
        typeof (event.target as HTMLElement).className === 'object'
          ? ''
          : (event.target as HTMLElement).className,
      clientX: event.clientX,
      clientY: event.clientY,
      scrollX: event.view.scrollX,
      scrollY: event.view.scrollY,
      innerWidth: event.view.innerWidth,
      innerHeight: event.view.innerHeight,
      outerWidth: event.view.outerWidth,
      outerHeight: event.view.outerHeight,
      timestamp: event.timeStamp,
      ...this.getDataClient()
    };
  }

  private getDataClient(): Omit<AccessControl, 'page'> {
    const deviceInfo = this.deviceService.getDeviceInfo();
    return {
      device: {
        ...deviceInfo,
        osVersion: deviceInfo.os_version,
        browserVersion: deviceInfo.browser_version
      },
      userAgent: this.deviceService.userAgent,
      cookie: this.document.cookie,
      app: this.appName,
      url: this.window.location.href
    };
  }

  private filter() {
    return (source: Observable<unknown>) =>
      source.pipe(
        filter(() => environment.production), //Permite apenas se for produção
        filter(() => {
          // Permite apenas se não for um usuário ebarn
          const authUser = this._storageService.getLocalUser();
          return authUser?.email ? !authUser.email.includes('@ebarn.com.br') : true;
        })
      );
  }

  private saveAccessControl(body: AccessControl): Observable<void> {
    return this.http.post<void>(`${this.url}/access-control`, body).pipe(take(1), shareReplay());
  }

  private saveClicksControl(body: ClicksControl): Observable<void> {
    return this.http.post<void>(`${this.url}/clicks-control`, body).pipe(take(1), shareReplay());
  }

  public landingAccessControlCreate(body: LandingAccessControl): Observable<LandingAccessControl> {
    return this.http
      .post<LandingAccessControl>(`${this.url}/landing-access-control`, body)
      .pipe(take(1), shareReplay());
  }

  public landingAccessControlFindByRefUrl(refUrl: string): Observable<LandingAccessControl[]> {
    const params = new HttpParams().append('refUrl', refUrl);
    return this.http
      .get<LandingAccessControl[]>(`${this.url}/landing-access-control/findByRefUrl`, {
        params
      })
      .pipe(take(1), shareReplay());
  }

  public landingButtonClicksCreate(body: LandingButtonClicks): Observable<LandingButtonClicks> {
    return this.http
      .post<LandingButtonClicks>(`${this.url}/landing-button-clicks`, body)
      .pipe(take(1), shareReplay());
  }

  public landingButtonClicksFindByRefUrl(refUrl: string): Observable<LandingButtonClicks[]> {
    const params = new HttpParams().append('refUrl', refUrl);
    return this.http
      .get<LandingButtonClicks[]>(`${this.url}/landing-button-clicks/findByRefUrl`, {
        params
      })
      .pipe(take(1), shareReplay());
  }

  public landingSignUpTriesCreate(body: LandingSignUpTries): Observable<LandingSignUpTries> {
    return this.http
      .post<LandingSignUpTries>(`${this.url}/landing-signUp-tries`, body)
      .pipe(take(1), shareReplay());
  }

  public landingSignUpTriesFindByRefUrl(refUrl: string): Observable<LandingSignUpTries[]> {
    const params = new HttpParams().append('refUrl', refUrl);
    return this.http
      .get<LandingSignUpTries[]>(`${this.url}/landing-signUp-tries/findByRefUrl`, {
        params
      })
      .pipe(take(1), shareReplay());
  }

  get window(): Window | null {
    return this.document.defaultView;
  }

  public getLast20TrackedUrls(): Observable<LandingAccessControl[]> {
    const params = new HttpParams().append('refUrl', null);
    return this.http
      .get<LandingAccessControl[]>(`${this.url}/landing-access-control/find-last-20`, {
        params
      })
      .pipe(take(1), shareReplay());
  }

  public getOne(id: string): Observable<LandingAccessControl> {
    return this.http.get<LandingAccessControl>(`${this.url}/${id}`).pipe(take(1));
  }

  public delete(id: string): Observable<LandingAccessControl> {
    return this.http.delete<LandingAccessControl>(`${this.url}/${id}`).pipe(take(1));
  }

  public findAccessControlByTerm(terms): Observable<any> {
    return this.http
      .get<any>(`${this.url}/access-control/find-by-term?terms=${terms}`)
      .pipe(take(1));
  }

  public findUniqueAccessControlByTerm(terms): Observable<any> {
    return this.http
      .get<any>(`${this.url}/access-control/find-unique-by-term?terms=${terms}`)
      .pipe(take(1));
  }

  public findAccessControlByCookie(cookie, page): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('cookie', cookie);
    params = params.append('page', page);
    return this.http
      .get<any[]>(`${this.url}/access-control/find-by-cookie`, {
        params
      })
      .pipe(take(1));
  }

  public findLastRegisterAccessControlByCookie(cookie): Observable<any> {
    let params = new HttpParams();
    params = params.append('cookie', cookie);
    return this.http
      .get<any>(`${this.url}/access-control/find-last-register-cookie`, {
        params
      })
      .pipe(take(1));
  }
}
