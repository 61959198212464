import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { AnalyticsEbarnService } from './services/analytics-ebarn.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('app', { static: true }) app: ElementRef<HTMLDivElement>;
  public settings: Settings;

  constructor(public appSettings: AppSettings, private analyticsService: AnalyticsEbarnService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.analyticsService.startListenEvents(this.app);
  }

  ngOnDestroy(): void {
    this.analyticsService.stopListenEvents();
  }
}
