import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';

import { REQUEST } from '@nguniversal/express-engine/tokens';
import * as moment from 'moment';

import { Empresa } from '../models/empresa';
import { Usuario } from '../models/usuario';
import { STORAGE_KEYS } from './storage_keys.config';

moment.locale('pt');

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private window: Window;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) protected request: Request
  ) {
    this.window = document.defaultView;
  }

  getLocalUser(): Usuario {
    const usr = this.getItem(STORAGE_KEYS.localUser);
    if (usr === null) {
      return new Usuario();
    } else {
      return Object.assign(new Usuario(), JSON.parse(usr));
    }
  }

  setLocalUser(obj: Usuario) {
    if (obj == null) {
      this.cleanLocalStorageUser();
    } else {
      this.setItem(STORAGE_KEYS.localUser, JSON.stringify(obj));
    }
  }

  getLocalFilterLead(): any {
    let filter = this.getItem(STORAGE_KEYS.localFilterLead);

    return JSON.parse(filter);
  }

  setLocalFilterLead(filter: object) {
    this.setItem(STORAGE_KEYS.localFilterLead, JSON.stringify(filter));
  }

  cleanLocalFilterLead(): void {
    this.removeItem(STORAGE_KEYS.localFilterLead);
  }

  getLocalPreferenciasCookies(): any {
    let filter = this.getItem(STORAGE_KEYS.localCookies);

    return JSON.parse(filter);
  }

  setLocalPreferenciasCookies(value: any) {
    this.setItem(STORAGE_KEYS.localCookies, JSON.stringify(value));
  }

  cleanLocalPreferenciasCookies(): void {
    this.removeItem(STORAGE_KEYS.localCookies);
  }

  getLocalGenerateThanksPage(): any {
    let filter = this.getItem(STORAGE_KEYS.generateThanksPage);

    return JSON.parse(filter);
  }

  setLocalGenerateThanksPage(value: any) {
    this.setItem(STORAGE_KEYS.generateThanksPage, JSON.stringify(value));
  }

  cleanLocalGenerateThanksPage(): void {
    this.removeItem(STORAGE_KEYS.generateThanksPage);
  }

  getLocalToken(): string {
    return this.getCookie(STORAGE_KEYS.localToken);
  }

  setLocalToken(token: string) {
    this.setCookie(STORAGE_KEYS.localToken, token, 7);
  }

  getLocalEmpresa(): Empresa {
    let usr = this.getItem(STORAGE_KEYS.localEmpresa);
    if (usr == null) {
      return null;
    } else {
      return JSON.parse(usr);
    }
  }

  setLocalEmpresa(obj: Empresa) {
    if (obj == null) {
      this.cleanLocalStorageEmpresa();
    } else {
      this.setItem(STORAGE_KEYS.localEmpresa, JSON.stringify(obj));
    }
  }

  cleanLocalStorageUser(): void {
    this.setItem(STORAGE_KEYS.localUser, '');
    this.removeItem(STORAGE_KEYS.localUser);
  }

  cleanLocalStorageEmpresa(): void {
    this.setItem(STORAGE_KEYS.localEmpresa, '');
    this.removeItem(STORAGE_KEYS.localEmpresa);
  }

  cleanLocalStorageToken(): void {
    this.cleanCookie(STORAGE_KEYS.localToken);
  }

  clean() {
    this.cleanLocalStorageUser();
    this.cleanLocalStorageToken();
    this.cleanLocalStorageEmpresa();
  }

  private setItem(key: string, value: string): void {
    if (isPlatformServer(this.platformId)) return;

    this.window.localStorage.setItem(key, value);
  }

  private getItem(key: string): string {
    if (isPlatformServer(this.platformId)) return null;

    return this.window.localStorage.getItem(key);
  }

  private removeItem(key: string): void {
    if (isPlatformServer(this.platformId)) return;

    return this.window.localStorage.removeItem(key);
  }

  private setCookie(key: string, value: string, exdays: number = 1): void {
    if (isPlatformServer(this.platformId)) return;

    let expires = moment().add(exdays, 'day').toDate().toUTCString();
    this.window.document.cookie = `${key}=${value};expires=${expires};path=/`;
  }

  private getCookie(key: string): string | null {
    const cookie = isPlatformServer(this.platformId)
      ? this.request.headers['cookie']
      : this.window.document.cookie;

    if (!cookie) return null;

    let name = key + '=';
    let ca = cookie?.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return null;
  }

  private cleanCookie(key: string): void {
    this.setCookie(key, '', -1);
  }
}
