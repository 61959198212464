import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from 'src/app/app.settings';
import { AbstractService } from '../abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  settings: Settings;

  private window: Window;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public appSettings: AppSettings,
    public http: HttpClient
  ) {
    this.window = document.defaultView;
    this.settings = this.appSettings.settings;
  }

  getLandingPageUrl(): string {
    return environment.urlLandingPages + '/';
  }

  redirecionarPara(url: string) {
    this.window.location.href = url;
  }

  redirecionarParaBlank(url: string) {
    this.window.open(url, '_blank');
  }

  public getDataConf(): Observable<any> {
    const urlArquivo = `/assets/data/conf.json`;

    return this.http.get<any>(urlArquivo).pipe(catchError(() => of([])));
  }
  getGrain(grain: string): string {
    switch (grain) {
      case 'milho':
        return 'Milho';
      case 'soja':
        return 'Soja';
      case 'feijao':
        return 'Feijão';
      case 'sorgo':
        return 'Sorgo';
      default:
        return '';
    }
  }

  isZeroOrNull(obj) {
    return obj === undefined || obj == null || Number(obj) === 0;
  }

  isUrl(data: string) {
    return data.toString().indexOf('https://') !== -1 || data.toString().indexOf('http://') !== -1;
  }

  mascaraCNPJ(str) {
    if (str) {
      str = str.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return str;
  }
  mascaraCPF(str) {
    if (str) str = str.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return str;
  }

  stringToQueryParams(queryParamsString: string): object {
    return queryParamsString?.split('&').reduce((acc, param) => {
      const [key, value] = param.split('=');
      return {
        ...acc,
        [key]: value
      };
    }, {});
  }

  relativeTimeThresholdMoment(moment, data: Date) {
    moment.relativeTimeThreshold('s', data.getSeconds());
    moment.relativeTimeThreshold('m', data.getMinutes());
    moment.relativeTimeThreshold('h', data.getHours());
    moment.relativeTimeThreshold('d', data.getDay());
    moment.relativeTimeThreshold('M', data.getMonth());
  }

  transformTextInUrl(str) {
    if (str) {
      return this.removerAcentos(str)
        .replace(/[^\w\s]/gi, '')
        .toLowerCase()
        .replace(/ /g, '-');
    }
    return str;
  }

  removerAcentos(str) {
    let com_acento = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
    let sem_acento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
    let novastr = '';
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < com_acento.length; a++) {
        if (str.substr(i, 1) == com_acento.substr(a, 1)) {
          novastr += sem_acento.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        novastr += str.substr(i, 1);
      }
    }
    return novastr;
  }

  getUrlSvgIconByNome(nome: string): string {
    switch (nome.toLowerCase()) {
      case 'soja':
        return '../assets/icons/svg/soy.svg';
      case 'feijao':
        return '../assets/icons/svg/bean.svg';
      case 'milho':
        return '../assets/icons/svg/corn.svg';
      case 'sorgo':
        return '../assets/icons/svg/sorgo.svg';
      case 'farelo-soja':
        return '../assets/icons/graos/farelo-soja.svg';
      case 'cana':
        return '../assets/icons/graos/cana.svg';
      case 'arroz':
        return '../assets/icons/graos/rice.svg';
      case 'cafe':
        return '../assets/icons/graos/cafe.svg';
      case 'trigo':
        return '../assets/icons/graos/trigo.svg';
      case 'algodao':
        return '../assets/icons/graos/caroco-de-algodao.svg';

      default:
        return '';
    }
  }

  formatarValor(valor: number): string {
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  groupByKey(collection, property): any[] {
    const groupedCollection: any[] = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }
      return previous;
    }, {});
    return groupedCollection;
  }

  isEbarn(): boolean {
    return environment.nome === 'ebarn' || environment.nome === 'Indústria Digital';
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  base64ToFile(dataurl: string, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  getImage(url: string) {
    return this.http.get(url, { responseType: 'blob' });
  }

  extractFilenameFromHeaders(headers: HttpHeaders): string {
    const contentDisposition = headers.get('content-disposition');
    const filename = contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim()
      .match(/"([^"]+)"/)[1];

    return filename;
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) reject(null);
      if(environment.nome === 'Germminar') reject(null)
      navigator.geolocation.getCurrentPosition(
        resp => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        err => {
          reject(err);
        }
      );
    });
  }

  fileSizeFormatted(fileSize: number) {
    const fileSizeFormatted =
      fileSize < 1024 * 1024
        ? (fileSize / 1024).toFixed(2) + 'KB'
        : (fileSize / 1024 / 1024).toFixed(2) + 'MB';

    return fileSizeFormatted;
  }

  isValidUrl(string) {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  }

  uploadImg(image: File): Observable<{ url: string }> {
    const url = `${environment.urlServerNode}/api/imgUpload`;
    const data = new FormData();
    data.append('image', image);
    return this.http.post<{ url: string }>(url, data);
  }
}
