import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { ILanding } from '../../interfaces/landing';

@Component({
  selector: 'app-landing-card-mobile',
  templateUrl: './landing-card-mobile.component.html',
  styleUrls: ['./landing-card-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingCardMobileComponent implements OnInit {
  @Input() public landingData: ILanding;
  @Input() public isHighLight: boolean;
  @ViewChild('img') img: ElementRef<HTMLImageElement>;
  public hasImage: boolean = true;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  handleNavigateToLanding() {
    this.router.navigateByUrl('/' + this.landingData.url);
  }

  public handleRemoveImg() {
    this.img.nativeElement.remove();
    this.hasImage = false;
  }
}
