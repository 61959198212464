import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './domain/config.service';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { Modulo } from '../models/modulo';

@Injectable({
  providedIn: 'root'
})
export class ModuloService {
  private url: string = '';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private localStorageService: StorageService
  ) {
    this.url = configService.getUrlServerCms() + '/modulos';
  }

  public save(modulo: Modulo): Observable<{ modules: Modulo[] }> {
    return this.http.put<{ modules: Modulo[] }>(`${this.url}`, modulo);
  }

  public find(id: string): Observable<{ modules: Modulo[] }> {
    return this.http.get<{ modules: Modulo[] }>(`${this.url}/${id}`);
  }

  public findAll(): Observable<{ modules: Modulo[] }> {
    return this.http.get<{ modules: Modulo[] }>(`${this.url}?parents=true`);
  }

  public findUserModules(): Observable<{ modules: Modulo[] }> {
    return this.http.get<{ modules: Modulo[] }>(`${this.url}/user-modules`, this.getHeaders());
  }

  public delete(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }

  public downloadSpreadSheet(): Observable<Blob> {
    return this.http.get(`${this.url}/download-spreadsheet`, { responseType: 'blob' });
  }

  getHeaders() {
    const token = this.localStorageService.getLocalToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token ? 'Bearer ' + token : ''
    });
    return { headers };
  }
}
