<header class="header-page mx-2 p-2" fxLayoutAlign="center center" fxLayoutGap="0.75rem">
  <div class="icon" *ngIf="icon && !svg">
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <div class="icon svg-div" *ngIf="svg && !icon">
    <img class="svg" [src]="svg" />
  </div>
  <div class="texts">
    <h1 [innerHtml]="title"></h1>
    <p [innerHtml]="description"></p>
  </div>
</header>
