import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/services/domain/config.service';
import { ILanding } from './interfaces/landing';
import { Pageable } from './interfaces/pageable';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  url: string;

  constructor(private httpclient: HttpClient, private configService: ConfigService) {
    this.url = configService.getUrlServiceNode();
  }

  public getLatestLandingPages(page: number = 1, limit: number = 10, isMobile?: boolean): Observable<Pageable> {
    return this.httpclient.get<Pageable>(
      this.url + `/api/landingpages/templates/latestlandingpages?page=${page}&limit=${limit}&ismobile=${isMobile}`
    );
  }
}
