import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';
import { HomeService } from './home.service';
import { ILanding } from './interfaces/landing';
import { Pageable } from './interfaces/pageable';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomeComponent implements OnInit {
  public verticalList: ILanding[] = [];
  public highlightedLanding: ILanding;
  public horizontalList: ILanding[];
  public landingPagesWithoutHighlight: ILanding[];
  public page: number = 0;
  public limit: number = 10;
  public totalPages: number = 10;
  public totalItems: number;

  private window: Window;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public appSettings: AppSettings,
    private homeservice: HomeService
  ) {
    this.window = document.defaultView;
  }

  ngOnInit(): void {
    this.getLandings();
  }

  public onPageChange(event: PageEvent) {
    this.landingPagesWithoutHighlight = null;
    this.page = event.pageIndex;
    this.limit = event.pageSize;
    this.getLandings();
  }

  public getLandings() {
    this.appSettings.settings.loadingSpinner = true;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      this.window.navigator.userAgent
    );
    this.homeservice.getLatestLandingPages(this.page + 1, this.limit, isMobile).subscribe(res => {
      this.handleFilterLandings(res);
      this.page = res.currentPage;
      this.totalPages = res.totalPages;
      this.totalItems = res.totalItems;
      this.appSettings.settings.loadingSpinner = false;
    });
  }

  private handleFilterLandings(landings: Pageable) {
    this.highlightedLanding =
      landings.serializedLandings.find(landing => landing.isHomeHighlight) ||
      landings.serializedLandings[0];

    this.landingPagesWithoutHighlight = landings.serializedLandings.filter(
      landing => JSON.stringify(landing) !== JSON.stringify(this.highlightedLanding)
    );

    this.horizontalList = this.landingPagesWithoutHighlight.filter((landing, index) => index <= 2);
    this.verticalList = this.landingPagesWithoutHighlight.filter((landing, index) => index > 2);
  }

  get linkButton(): string {
    return environment.homeApp;
  }
}
