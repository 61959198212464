import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { ILanding } from '../../interfaces/landing';

@Component({
  selector: 'app-landing-card',
  templateUrl: './landing-card.component.html',
  styleUrls: ['./landing-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LandingCardComponent implements OnInit {
  @Input() public landingData: ILanding;
  @Input() public isHighLight: boolean;
  @Input() public isSubHighlight: boolean;
  @ViewChild('img') img: ElementRef<HTMLImageElement>;
  public hasImage: boolean = true;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  handleNavigateToLanding() {
    if (this.landingData?.url) {
      this.router.navigateByUrl('/' + this.landingData.url);
    }
  }

  public handleRemoveImg() {
    this.img.nativeElement.remove();
    this.hasImage = false;
  }
}
