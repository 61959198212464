<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  [class.white]="isWhite"
  class="landing-standard-header"
>
  <div class="home-ebarn-logo">
    <img src="assets/logo-130x70.png" />
  </div>
  <a class="home-action-buttons" [href]="link" [target]="openInNewTab ? 'blank' : '_self'">
    {{ title }}&nbsp; <mat-icon>{{ icon }}</mat-icon>
  </a>
</div>
