import { Empresa } from './empresa';
export class Usuario {
  id: number;
  nome: string;
  bio?: string;
  dataNascimento: string;
  email: string;
  cpf: string;
  senha: string;
  etapa: number;
  telefone1: string;
  telefone2: string;
  perfil: string;
  perfilUsuario: string;
  perfilEmpresa: string;
  aprovado: boolean;
  rejeitado: boolean;
  empresaPadrao: Empresa;
  ebarn: boolean;
  verificationMail: string;
  avatar: string;
  tokenFb: string;
  tokenAplicacao: string;
  urlInvite: string;
  refreshToken: string;
  atendente: boolean;
  imagemEmpresa?: string;
  accountId?: string;
  isVerified?: boolean;
  idComplyCube?: string;
  authentic?: boolean;

  isProdutor(): boolean {
    return this.perfil === 'PRODUTOR' || this.perfil === 'P';
  }

  isFornecedor(): boolean {
    return this.perfil === 'FORNECEDOR' || this.perfil === 'F';
  }

  isIndustria(): boolean {
    return this.perfil === 'INDUSTRIA';
  }
}

export interface ResponseFindUsuariosComPaginacaoEFiltro {
  usuarios: Usuario[];
  totalPaginas: number;
  paginaAtual: number;
  registrosPorPagina: number;
  totalRegistros: number;
}
