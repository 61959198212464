import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './core/guard/auth.guard';
import { RedirectLandingGuard } from './core/guard/redirect-landing.guard';
import { MenuComponent } from './pages/gerenciadores/menu/menu.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: () =>
      import('./pages/politica-de-privacidade/politica-de-privacidade.module').then(
        m => m.PoliticaDePrivacidadeModule
      )
  },
  {
    path: 'termos-de-uso',
    loadChildren: () =>
      import('./pages/termos-de-uso/termos-de-uso.module').then(m => m.TermosDeUsoModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    component: MenuComponent,
    loadChildren: () =>
      import('./pages/gerenciadores/gerenciadores.module').then(m => m.GerenciadoresModule)
  },

  {
    path: 'confirmacao/:token-email',
    loadChildren: () =>
      import('./pages/confirmacao-dupla/confirmacao-dupla.module').then(
        m => m.ConfirmacaoDuplaModule
      )
  },
  {
    path: 'unsub-mail/:mail',
    loadChildren: () => import('./pages/unsub-mail/unsub-mail.module').then(m => m.UnsubMailModule)
  },
  {
    path: 'landing/:refurl',
    canActivate: [RedirectLandingGuard],
    loadChildren: () =>
      import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: ':refurl',
    loadChildren: () =>
      import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
