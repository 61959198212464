<a
  mat-list-item
  [ngStyle]="{ 'padding-left': depth * 16 + 'px' }"
  (click)="onItemSelected(item)"
  [ngClass]="{ expanded: expanded }"
  [activated]="router.isActive(item.route, true)"
>
  <mat-icon class="route-icon" matListItemIcon>{{ item.iconName }}</mat-icon>
  <span matListItemTitle fxLayout="row" fxLayoutAlign="space-between center">
    {{ item.displayName | uppercase }}
    <mat-icon *ngIf="item.children && item.children.length" [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-item-menu *ngFor="let child of item.children" [item]="child" [depth]="depth + 1">
  </app-item-menu>
</div>
