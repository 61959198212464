import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { Usuario } from 'src/app/models/usuario';
import { ModuloService } from 'src/app/services/modulo.service';
import { NavService } from 'src/app/services/nav.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, AfterViewInit {
  @ViewChild(MatDrawer, { static: true }) drawer: MatDrawer;
  public settings: Settings;
  public user: Usuario;
  navItems: any[];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    public appSettings: AppSettings,
    private storageService: StorageService,
    private navService: NavService,
    private moduleService: ModuloService
  ) {
    this.settings = this.appSettings.settings;
    this.user = this.storageService.getLocalUser();
    this.configMenu();
  }

  ngOnInit(): void {
    this.navService.drawerClose.subscribe(res => {
      if (res) {
        this.drawer.close();
      }
    });
  }

  configMenu() {
    this.moduleService.findUserModules().subscribe(res => {
      let menu = res.modules.map(item => ({
        displayName: item.titulo,
        iconName: item.icone,
        route: item.path,
        children: item.modulosFilhos?.map(child => ({
          displayName: child.titulo,
          iconName: child.icone,
          route: child.path
        }))
      }));

      if (isPlatformBrowser(this.platformId) && this.user?.email?.includes('@ebarn.com.br')) {
        menu.unshift({
          displayName: 'GERENCIADOR DE MODULOS',
          iconName: 'view_module',
          route: 'admin/gerenciador-modulos',
          children: null
        });
      }

      menu.unshift({
        displayName: 'DASHBOARD',
        iconName: 'home',
        route: 'admin/dashboard',
        children: null
      });

      this.navItems = menu;
    });
  }

  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }

  logout() {
    this.router.navigateByUrl('/login');
    this.storageService.cleanLocalStorageUser();
    this.storageService.cleanLocalStorageToken();
    this.storageService.cleanLocalStorageEmpresa();
  }
}
