import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private window: Window;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private storageService: StorageService,
    public router: Router
  ) {
    this.window = document.defaultView;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storageService.getLocalToken();

    if (token && this.isRequestToEbarn(request)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    } else if (!token) {
      this.storageService.clean();
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && !request.url.includes('login')) {
            this.storageService.clean();

            const redirect = encodeURIComponent(this.router.routerState.snapshot.url);
            // Para cancelar as demais request http
            this.window.location.href = `login?redirect=${redirect}`;
          }
        }

        return throwError(() => err);
      })
    );
  }

  private isRequestToEbarn({ url }: HttpRequest<any>) {
    return (
      (url.includes(environment.urlServiceJava) ||
        url.includes(environment.urlServerNode) ||
        url.includes(environment.urlEbarnTemplates)) &&
      !url.includes('/empresa/estados') &&
      !url.includes('/empresa/cidades')
    );
  }
}
