import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { EbarnCabecalhoPaginaModule } from 'src/app/shared/ebarn-cabecalho-pagina/ebarn-cabecalho-pagina.module';
import { LandingCardComponent } from './components/landing-card/landing-card.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LandingCardMobileComponent } from './components/landing-card-mobile/landing-card-mobile.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import { StandardHeaderLandingModule } from 'src/app/shared/components-landing/standard-header-landing/standard-header-landing.module';

const brRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return `0 de ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex =
    startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${length}`;
};

export function getBrPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Itens por página:';
  paginatorIntl.nextPageLabel = 'Próx. página';
  paginatorIntl.previousPageLabel = 'Voltar página';
  paginatorIntl.getRangeLabel = brRangeLabel;

  return paginatorIntl;
}

@NgModule({
  declarations: [HomeComponent, LandingCardComponent, LandingCardMobileComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    EbarnCabecalhoPaginaModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatPaginatorModule,
    StandardHeaderLandingModule
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: getBrPaginatorIntl() }]
})
export class HomeModule {}
