<div class="home-container">
  <standard-header-landing
    *ngIf="!appSettings.settings.loadingSpinner"
    title="Home"
    icon="home"
    [link]="'/'"
    [openInNewTab]="false"
  ></standard-header-landing>
  <div class="cards-area" *ngIf="landingPagesWithoutHighlight">
    <main>
      <app-landing-card [landingData]="highlightedLanding" [isHighLight]="true"></app-landing-card>
      <div class="content">
        <app-landing-card
          *ngFor="let landing of horizontalList; let i = index"
          [isSubHighlight]="true"
          [landingData]="horizontalList[i]"
        ></app-landing-card>
      </div>
    </main>
    <section class="cards-vertical-section">
      <app-landing-card
        *ngFor="let landing of verticalList; let i = index"
        [landingData]="verticalList[i]"
      ></app-landing-card>
    </section>
    <section class="cards-mobile-section">
      <app-landing-card
        *ngFor="let landing of landingPagesWithoutHighlight; let i = index"
        [landingData]="landingPagesWithoutHighlight[i]"
      ></app-landing-card>
    </section>
  </div>
  <mat-paginator
    [ngClass]="{ 'paginator-hidden': !landingPagesWithoutHighlight}"
    [length]="totalItems"
    [pageSize]="this.limit"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Selecionar pagina"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
