<a
  href="{{ '/' + this.landingData.url }}"
  class="landing-card"
  [ngClass]="{
    highlight: isHighLight,
    'card-without-image': !hasImage,
    subHighlight: isSubHighlight
  }"
  *ngIf="landingData"
>
  <img
    src="{{ landingData?.image?.url }}"
    alt="{{ landingData?.image?.name }}"
    (error)="handleRemoveImg()"
    #img
  />
  <div class="line-before-title"></div>
  <h3>{{ landingData?.name }}</h3>
  <p>{{ landingData?.descriptionSeo }}</p>
</a>
