import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class CustomOverlayContainer extends OverlayContainer {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    @Inject(PLATFORM_ID) public platformId: Platform
  ) {
    super(document, platformId);
  }

  _createContainer(): void {
    let container = this.document.createElement('div');
    container.classList.add('cdk-overlay-container');
    this.document.getElementById('app').appendChild(container);
    this._containerElement = container;
  }
}
