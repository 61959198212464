import { EnvironmentModel } from 'src/environments/environment.model';

export const environment: EnvironmentModel = {
  production: false,
  urlServiceJava: 'https://apihomol.ebarn.com.br',
  urlServerNode: 'https://apinode.coop.ebarn.com.br',
  urlEbarnTemplates: 'https://apinodehomol.ebarn.com.br',
  appUrl: 'https://teste.cxm.cooperativadigital.ebarn.com.br',
  homeApp: 'https://teste.cooperativadigital.ebarn.com.br',
  urlLandingPages: 'https://teste.cxm.cooperativadigital.ebarn.com.br',
  nome: 'Cooperativa Digital',
  whatsappSuporte: '',
  indexHtml: {
    facebookDomainVerification: '',
    pixelFacebook: '',
    googleAnalytics: '',
    googleTagManager: '',
    pixelLinkedin: '',
    pixelTwitter: ''
  },
  googlePlay: null,
  appleStore: null,
  redesSociais: {
    youtube: null,
    facebook: null,
    instagram: null,
    linkedin: null
  },
  repos3: 'https://repo-files-customers.s3.sa-east-1.amazonaws.com/cooperativadigital',
  repos3Master: 'https://repo-files-customers.s3.sa-east-1.amazonaws.com/ebarn-homol',
  mapBoxUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  mapBoxToken:
    'pk.eyJ1IjoianJmZXJuYW5kbyIsImEiOiJjbGFxdzE0cG8wa3R0M3Buc3ZzZm93cG04In0.8g1J41Qw3Hl9zVlBpKmnUw',
  programaPontosV2: true
};
