<mat-toolbar style="height: 60px" color="primary">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="py-1 w-100">
    <button mat-mini-fab (click)="drawer.toggle()" matTooltip="Abrir menu">
      <mat-icon fontIcon="menu" />
    </button>
    <a routerLink="/" fxLayout="row" fxLayoutAlign="start center">
      <img ngSrc="assets/img/ebarn-crm-logo.png" alt="logo" height="33" width="156"/>
      <span class="mx-2"></span>
    </a>
  </div>
</mat-toolbar>
<mat-drawer-container
  style="height: calc(100vh - 60px)"
  class="example-container"
  hasBackdrop="true"
>
  <mat-drawer #drawer mode="over">
    <div fxLayout="column" fxLayoutAlign="center center" class="p-2 pb-0 container-user">
      <img class="user-img" ngSrc="assets/img/users/default-user.jpg" alt="Foto do usuário" height="200" width="200"/>
      <h2 class="text-truncate">{{ user?.nome }}</h2>
      <p>{{ user?.empresaPadrao?.fantasia ?? 'Não informado' }}</p>
      <small class="secondary-text-color">{{ user?.email }}</small>
    </div>
    <mat-nav-list>
      <app-item-menu *ngFor="let item of navItems" [item]="item"></app-item-menu>

      <mat-list-item (click)="logout()" style="width: 100%">
        <mat-icon class="icon-menu" matListItemIcon>power_settings_new</mat-icon>
        <span matListItemTitle>SAIR</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
