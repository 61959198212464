import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-ebarn-cabecalho-pagina',
  templateUrl: './ebarn-cabecalho-pagina.component.html',
  styleUrls: ['./ebarn-cabecalho-pagina.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EbarnCabecalhoPaginaComponent {
  @Input() icon: string;
  @Input() svg: string;
  @Input() title: string;
  @Input() description: string;
}
