import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor() {}

  getUrlServiceJava(): string {
    return environment.urlServiceJava;
  }

  getUrlServiceNode(): string {
    return environment.urlServerNode;
  }

  getUrlServerCms(): string {
    return environment.urlServerNode + '/api';
  }

  getUrlEBarnTemplates(): string {
    return environment.urlEbarnTemplates + '/api';
  }

  getUrlServerCatmat(): string {
    return environment.urlServerNode + '/api/catmat';
  }
}
