import { APP_ID, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavService } from './services/nav.service';

import { ScrollToModule } from '@paulvmoreau/ngx-scroll-to';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayContainer } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideNgxMask } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { InfoDialogComponent } from './pages/gerenciadores/gestor-landing/components/info-dialog/info-dialog.component';
import { HomeModule } from './pages/home/home.module';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';

registerLocaleData(localeBr, 'pt');

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
    MatChipsModule,
    MatIconModule,
    ScrollToModule.forRoot(),
    HomeModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule,
  ],
  declarations: [AppComponent, InfoDialogComponent],
  providers: [
    MatDatepickerModule,
    MatMomentDatetimeModule,
    provideClientHydration(),
    provideNgxMask(),
    { provide: APP_ID, useValue: 'serverApp' },
    AppSettings,
    NavService,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'ebarn-dialog',
        backdropClass: 'ebarn-background-modal',
        hasBackdrop: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
