import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'standard-header-landing',
  templateUrl: './standard-header-landing.component.html',
  styleUrls: ['./standard-header-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardHeaderLandingComponent implements OnInit {
  @Input() isWhite: boolean = false;
  @Input() textOnly: boolean = false;
  @Input() title: string = 'Entrar';
  @Input() link: string = 'https://teste.cxm.ebarn.com.br/';
  @Input() openInNewTab: boolean = true;
  @Input() icon: string = 'login';

  constructor() {}

  ngOnInit(): void {}
}
